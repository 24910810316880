import { ConfigHeader } from "../../types/config";
import * as Icons from "../icons/socialIcon";

export const configHeader: ConfigHeader = {
  megaMenu: {
    newsletter: {
      text: "Sign up for our weekly newsletters for the best fashion, beauty, travel and exclusive events",
      url: "/newsletter-signup",
      buttonText: "SIGN UP",
    },
    closeButton: {
      type: "icon",
      placement: "right",
    },
  },
  socialMedia: {
    target: "_blank",
    icons: [
      {
        icon: Icons.facebookIcon,
        name: "Facebook",
        href: "http://www.facebook.com/sg.Herworld",
      },
      {
        icon: Icons.instagramIcon,
        name: "Instagram",
        href: "http://instagram.com/herworldsingapore",
      },
      {
        icon: Icons.linkedinIcon,
        name: "LinkedIn",
        href: "http://www.linkedin.com/company/herworldsingapore",
      },
      {
        icon: Icons.tiktokIcon,
        name: "Tiktok",
        href: "https://www.tiktok.com/@herworldsingapore",
      },
    ],
  },
};
